function Footer() {
        return (
            <footer className="footer mt-auto py-3">
                <div className="container text-center">
                    © Simone Muraro. All rights reserved.
                </div>
            </footer>
        )
}

export default Footer